.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-image: url('img/white.jpg');
  background-size: cover;
  background-attachment: fixed;
}

a {
  text-decoration: none;
  display: flex;
}

#avatar-rossi {
  width: 200px;
  height: 200px;
  object-fit: contain;
  padding: 0px;
  box-shadow: rgb(100 100 100 / 50%) 0px 0px 10px;
  border-radius: 150px;
  min-width: 150px !important;
  min-height: 150px !important;
}

#grid {
  min-height: 20vh;
  padding: 45px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 15px;
}

.text {
  text-align: center;
  margin-top: 20px;
}

.text h2 {
  font-size: 24px;
  color: black;
}

.text p {
    font-size: 16px;
    color: black;
}

.social {
  margin-top: 30px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
}
 
.social-icon {
  box-shadow: rgb(100 100 100 / 50%) 0px 0px 10px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 2px;
  border: 1px solid white;
  background: rgb(18, 18, 18);
  border-radius: 100px;
  margin: 10px;
  transition: all 0.5s ease 0s;
}

.social-icon img {
  width: 50px;
  height: 50px;
}

.buttons-grid {
  display: flex;
  margin: 30px;
  flex-direction: column;
}

.button {
  transition: all 0.5s ease 0s;
  background: white;
  border-radius: 100px;
  padding: 15px 50px;
  -webkit-box-align: center;
  align-items: center;
  margin: 15px;
  box-shadow: rgb(220 220 220) 0px 0px 20px;
  color: black;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.button:hover {
  box-shadow: rgb(200 200 200) 0px 0px 20px;
  background: #2b94ff;
  color: white;
}
  
.content {
  margin: 30px auto;
  max-width: 620px;
}

.contato {
  display: flex;
  align-items: center;
}

.contato img {
  width: 30px;
  height: 30px;
}

.contato span {
  color: black;
}

@media only screen and (min-width: 960px) {

  .buttons-grid {
    flex-direction: row;
  }
}
